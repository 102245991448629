import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout/Layout";
import pathBuilder from "../../utils/pathBuilder";
import ContentfulRenderer from "../../components/System/ContentfulRenderer";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Link } from "gatsby";
import shopInfo from "../../../data/shopInfo";
import { ProductSchema } from "../../components/Seo/Schemas";
import { useAppContext } from "../../context/appProvider";
import { pushDetailView } from "../../utils/data-layer-ecom";

import {
  ProductHeaderWrapper,
  ProductHeaderContent,
  ProductImage,
  ProductDetails,
  CartBar,
  ButtonPrimary,
  BackToShop,
  ProductBody,
} from "./styled";

const Book = (props) => {
  const {
    title,
    image,
    price,
    content,
    numberOfPages,
    subtitle,
    level,
    format,
    contentLanguage,
    sku,
    slug,
    productId,
    __typename,
    contentful_id,
    node_locale,
    // fileIdSnipcart,
    description,
    previewAsset,
  } = props.data.book;

  const ctx = useAppContext();
  const { state, dispatch } = ctx;

  const environment = {
    lang: node_locale,
    id: contentful_id,
    path: pathBuilder(node_locale, __typename, slug),
    altHrefLang: [
      {
        lang: node_locale,
        path: pathBuilder(node_locale, __typename, slug),
      },
      {
        lang: props.data.altProduct.node_locale,
        path: pathBuilder(
          props.data.altProduct.node_locale,
          props.data.altProduct.__typename,
          props.data.altProduct.slug,
        ),
      },
    ],
  };

  const seo = {};
  const [isBought, set_isBought] = useState(false);
  const [isInCart, set_isInCart] = useState(false);

  useEffect(() => {
    if (
      state &&
      state.user &&
      state.user.purchased &&
      state.user.purchased.find((e) => e.productId === productId)
    ) {
      set_isBought(true);
      return;
    }

    if (
      state &&
      state.cart &&
      state.cart.items &&
      state.cart.items.find((e) => e.id === productId)
    ) {
      set_isInCart(true);
    }
  }, [state?.cart?.items]);

  if (props.data.book.seoDescription) {
    seo.description = props.data.book.seoDescription;
  }

  if (props.data.book.seoTitle) {
    seo.title = props.data.book.seoTitle;
  } else {
    seo.title = props.data.book.title;
  }
  environment.seo = seo;

  const path =
    process.env.GATSBY_ROOT_URL + pathBuilder(node_locale, __typename, slug);
  // const id = contentful_id;
  const imageUrl = image.fixed.images.fallback.src;
  // const fileId = "" + fileIdSnipcart;
  const uiContent = shopInfo[environment.lang];
  const productDescription = content;
  let productMetaDescription = seo.description;
  if (description && description.childMarkdownRemark) {
    productMetaDescription = description.childMarkdownRemark.excerpt;
  }

  useEffect(() => {
    pushDetailView({
      id: productId,
      name: title,
      category: "books",
      price: price,
      location: "PDP",
    });
  }, []);

  return (
    <Layout environment={environment}>
      <ProductSchema
        name={title}
        image={imageUrl}
        description={productMetaDescription}
        price={price}
        path={path}
        sku={sku}
      />
      <ProductHeaderWrapper>
        <ProductImage>
          {image ? <GatsbyImage image={image.main} alt={title} /> : null}
        </ProductImage>
        <ProductHeaderContent w="50">
          <h1>{title}</h1>
          <h3>{subtitle}</h3>
          {previewAsset?.file?.url ? (
            <a
              className="sneakpeak"
              target="_black"
              href={previewAsset.file.url}
            >
              View sneakpeek
            </a>
          ) : null}

          <ProductDetails>
            {contentLanguage ? (
              <li>
                {uiContent.ui.language}:<strong>{" " + contentLanguage}</strong>
              </li>
            ) : null}
            {level ? (
              <li>
                {uiContent.ui.level}:<strong>{" " + level}</strong>
              </li>
            ) : null}
            {numberOfPages ? (
              <li>
                {uiContent.ui.pages}:<strong>{" " + numberOfPages}</strong>
              </li>
            ) : null}
            {format ? (
              <li>
                {uiContent.ui.format}:<strong>{" " + format}</strong>
              </li>
            ) : null}
          </ProductDetails>
          <CartBar>
            {price ? (
              <span className="price">
                {uiContent.ui.price}: {price}€
              </span>
            ) : null}
            {isBought ? (
              <>
                <ButtonPrimary>See my book</ButtonPrimary>
              </>
            ) : isInCart ? (
              <Link to={"/en/cart"}>
                <ButtonPrimary>Finish your order</ButtonPrimary>
              </Link>
            ) : (
              <ButtonPrimary
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "ADD-TO-CART",
                    payload: {
                      id: productId,
                      title: title,
                      image: image.fixed.images.fallback.src,
                      type: "digital-book",
                      slug: pathBuilder(node_locale, __typename, slug),
                      price: price,
                    },
                  });
                }}
              >
                {uiContent.ui.addToCart}
              </ButtonPrimary>
            )}
          </CartBar>
        </ProductHeaderContent>
      </ProductHeaderWrapper>

      <ProductBody>
        <div className="content-card">
          <ContentfulRenderer
            json={productDescription}
            lang={environment.lang}
          />
          <BackToShop>
            <Link to={uiContent.ui.shopSlug}>
              &larr; {uiContent.ui.backToShop}
            </Link>
          </BackToShop>
          <Breadcrumbs
            lang={environment.lang}
            type={__typename}
            path={environment.path}
            title={title}
          />
        </div>
      </ProductBody>
    </Layout>
  );
};

Book.propTypes = {
  data: PropTypes.object.isRequired,
};
export default Book;

export const bookQuery = graphql`
  query bookQuery($lang: String!, $id: String!, $altLang: String!) {
    book: contentfulBook(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      title
      description {
        childMarkdownRemark {
          excerpt(format: PLAIN)
        }
      }
      slug
      node_locale
      contentful_id
      price
      fileIdSnipcart
      numberOfPages
      subtitle
      format
      contentLanguage
      level
      seoTitle
      seoDescription
      sku
      content {
        raw
      }
      __typename
      previewAsset {
        file {
          contentType
          fileName
          url
          details {
            size
          }
        }
      }

      image {
        fixed: gatsbyImageData(width: 150)
        main: gatsbyImageData(width: 720, layout: CONSTRAINED)
      }
      productId
    }
    altProduct: contentfulBook(
      contentful_id: { eq: $id }
      node_locale: { eq: $altLang }
    ) {
      title
      slug
      node_locale
      __typename
    }
  }
`;
