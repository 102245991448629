import { theme } from "../../theme/variables";
import styled from "styled-components";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const ProductHeaderWrapper = styled.div`
  background-color: ${(props) => props.theme.primary};
  margin-bottom: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: ${theme.colorSystemWhite};
  padding: 0px 32px;

  h1,
  h2,
  h3,
  h4,
  span,
  li,
  ul,
  button,
  p {
    font-family: ${theme.fontHeaders};
    color: inherit;
  }
  ${MEDIA.TABLET`
    flex-direction: column;
		margin-bottom: 68px;
    padding: 0px 12px;


  `}
  .sneakpeek {
    color: white;
    border-bottom: 1px solid white;
    margin: 10px 0;
    display: inline-block;
    font-weight: 700;
  }
  box-shadow: 4px 0px 14px ${(props) => props.theme.default_darkerTwo};
`;
export const ProductHeaderContent = styled.div`
  margin: auto 0px;
  padding-left: 50px;
  max-width: 800px;
  ${MEDIA.TABLET`
    padding-left:0;
  `};
`;

export const ProductImage = styled.div`
  width: 50%;
  max-width: 540px;
  box-shadow: 4px 0px 14px ${(props) => props.theme.darkerTwo},
    -4px -4px 10px ${(props) => props.theme.lighterOne};
  margin-top: 32px;

  .gatsby-image-wrapper {
    margin-bottom: -200px;
  }
  ${MEDIA.TABLET`
		width: 100%;
		box-shadow: none;
		.gatsby-image-wrapper{
			margin: 16px 16px 10px 16px;
			box-shadow: 4px 0px 14px ${(props) => props.theme.darkerTwo},
		-4px -4px 10px ${(props) => props.theme.lighterOne};
		}
	`}
`;
export const ProductDetails = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-left: -10px;
  max-width: 640px;
  display: flex;
  flex-direction: column;

  li {
    padding: 0px;
    margin: 0px;
    font-size: 1rem;
    line-height: 1.3;
    /* font-weight: 700; */
    display: inline-block;
    padding: 0px 10px;
  }
`;

export const CartBar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  .price {
    font-size: 1.4rem;
    font-weight: 700;
  }

  padding: 32px 0px;
  ${MEDIA.MIN_TABLET`
		justify-content: flex-start;
		button{
			margin-left: 16px;
		}
    `}
`;
export const ButtonPrimary = styled.button`
  padding: 10px 22px;
  border-radius: 30px;
  color: white;
  border: none;
  display: inline-block;
  margin: 0;
  font-weight: 700;
  font-size: 1.2rem;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.contrast_halfUp},
    ${(props) => props.theme.contrast_primary}
  );
  box-shadow: 5px 5px 7px ${(props) => props.theme.darkerOne},
    -2px -2px 2px ${(props) => props.theme.contrast_lighterTwo};
`;

export const BackToShop = styled.div`
  display: block;
  margin: 100px 0;
  font-weight: 700;
  text-decoration: underline;
`;
export const ProductBody = styled.div`
  max-width: ${theme.contentWideMaxWidth};
  margin: 200px auto;
  padding: 32px;
  .content-card {
    max-width: 640px;
    /* margin: 0px 0px auto auto; */
    margin: auto;
    background: linear-gradient(
      135deg,
      ${(props) => props.theme.default_lighterTwo},
      ${(props) => props.theme.default_lighterOne}
    );
    padding: 42px 62px;
    border-radius: 20px;
    box-shadow: 5px 5px 7px ${(props) => props.theme.default_darkerOne},
      -2px -2px 4px ${(props) => props.theme.default_lighterTwo};
  }
  ${MEDIA.TABLET`
		margin: 0 auto;
		.content-card {
			padding: 32px 16px;
		}
	`}
`;
